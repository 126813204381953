import React from "react"
import styled, { createGlobalStyle } from "styled-components"

import Seo from "components/controller/seo"

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${props => props.theme.colors.offWhite};
  }
`

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  font-family: "Arimo", sans-serif;
  padding: 20px;

  @media (min-width: 700px) {
    padding: 50px 50px;
  }
`

const H1 = styled.h1`
  font-family: "Merriweather", serif;
  font-weight: bold;
  font-size: 48px;

  @media (max-width: 700px) {
    font-size: 24px;
  }
`

const FAQPage = () => {
  return (
    <>
      <GlobalStyle />
      <Seo title="FAQ" />

      <Wrapper>
        <H1>Frequently Asked Questions</H1>

        <h2>Can I cancel my subscription?</h2>
        <p>
          Yes! You can cancel your subscription at any time on our website
          through your account portal. There are no fees or hidden surprises.
        </p>

        <h2>Is billing secure?</h2>
        <p>
          Billing is handled and secured by Stripe, a platform trusted by brands
          such as Google, Shopify, and Amazon.
        </p>

        <h2>
          If I purchase an adventure on Roll20, do I also get the PDF files?
        </h2>
        <p>
          Unfortunately, we have no way to verify Roll20 purchases, so we cannot
          provide the associated PDFs.
        </p>
      </Wrapper>
    </>
  )
}

export default FAQPage
